// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconSettings2 } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconSettings2
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const settings = {
    id: 'settings',
    title: '',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings', ///utils/util-shadow
            icon: icons.IconSettings2,
            breadcrumbs: false
        }
    ]
};

export default settings;
