import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';

import settings from './settings';

import newsAndSupport from './newsAndSupport';

import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, utilities, settings, newsAndSupport]
};

export default menuItems;
