// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconNews, IconHelp } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconNews,
    IconHelp
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const support = {
    id: 'news-and-support',
    title: '',
    type: 'group',
    children: [
        {
            id: 'news',
            title: 'News',
            type: 'item',
            url: '/news',
            icon: icons.IconNews,
            breadcrumbs: false
        },
        {
            id: 'support',
            title: 'Support',
            type: 'item',
            url: '/support',
            icon: icons.IconHelp,
            breadcrumbs: false
        }
    ]
};

export default support;
