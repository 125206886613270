import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// Staking
const Staking = Loadable(lazy(() => import('views/staking/Staking')));

// Mining
const Mining = Loadable(lazy(() => import('views/mining/Mining')));

// Trading
const Trading = Loadable(lazy(() => import('views/trading/Trading')));

// History
const History = Loadable(lazy(() => import('views/history/History')));

// Support
const Support = Loadable(lazy(() => import('views/support/Support')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'staking',
            element: <Staking />
        },
        {
            path: 'mining',
            element: <Mining />
        },
        {
            path: 'trading',
            element: <Trading />
        },
        {
            path: 'history',
            element: <History />
        },
        {
            path: 'history',
            element: <History />
        },
        {
            path: 'wallet',
            children: [
                {
                    path: 'wallet',
                    element: <SamplePage />
                }
            ]
        },
        {
            path: 'history',
            children: [
                {
                    path: 'history',
                    element: <SamplePage />
                }
            ]
        },
        {
            path: 'partners',
            children: [
                {
                    path: 'partners',
                    element: <SamplePage />
                }
            ]
        },
        {
            path: 'news',
            children: [
                {
                    path: 'news',
                    element: <SamplePage />
                }
            ]
        },
        {
            path: 'settings',
            children: [
                {
                    path: 'settings',
                    element: <SamplePage />
                }
            ]
        },
        {
            path: 'support',
            element: <Support />
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
