// assets
import { IconTypography, IconPalette, IconShadow, IconCashBanknote, IconWallet, IconHistory, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconCashBanknote,
    IconWallet,
    IconHistory,
    IconUsers
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    children: [
        {
            id: 'icons',
            title: 'Investments',
            type: 'collapse',
            icon: icons.IconCashBanknote,
            children: [
                {
                    id: 'staking',
                    title: 'Staking',
                    type: 'item',
                    url: '/staking',
                    breadcrumbs: false
                },
                {
                    id: 'mining',
                    title: 'Mining',
                    type: 'item',
                    url: '/mining',
                    breadcrumbs: false
                },
                {
                    id: 'trading',
                    title: 'Trading',
                    type: 'item',
                    url: '/trading',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: '/wallet', //utils/util-typography
            icon: icons.IconWallet,
            breadcrumbs: false
        },

        {
            id: 'history',
            title: 'History',
            type: 'item',
            url: '/history', //utils/util-color
            icon: icons.IconHistory,
            breadcrumbs: false
        },
        {
            id: 'partners',
            title: 'Partners',
            type: 'item',
            url: '/partners', //utils/util-shadow
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};

export default utilities;
